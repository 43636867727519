import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */
function addEcho () {
  window.Pusher = Pusher;

  if (!window.Echo) {
    if (import.meta.env.VITE_BROADCAST_DRIVER === 'pusher') {
      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: import.meta.env.VITE_PUSHER_APP_KEY,
        cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
        forceTLS: true
      });
    } else {
      window.Echo = new Echo({
        broadcaster: 'reverb',
        key: import.meta.env.VITE_REVERB_APP_KEY,
        wsHost: import.meta.env.VITE_REVERB_HOST,
        wsPort: import.meta.env.VITE_REVERB_PORT,
        wssPort: import.meta.env.VITE_REVERB_PORT,
        forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
        enabledTransports: ['ws', 'wss']
      });
    }
  }
}

function removeEcho () {
  if (window.Echo) {
    window.Echo.disconnect();
    window.Echo = null;
  }
}

export {
  addEcho,
  removeEcho
};
